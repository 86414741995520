<template>
  <div class="p-2">
        <ValidationObserver ref="datos_basicos">
          <div>
            <validation-provider
            v-slot="{ errors }"
            name="fields_academico.group_ano_escolar.nu_id_pais.nu_id_pais"
            :rules=fields_academico.group_ano_escolar.nu_id_pais.caracteristicas.required
            >
                <v-autocomplete
                v-model="fields_academico.group_ano_escolar.nu_id_pais.nu_id_pais"
                :items="listaPaises"
                :loading="isLoading"
                :error-messages="errors"
                hide-no-data
                hide-selected
                item-text="tx_denominacion"
                item-value="nu_id_pais"
                :label=fields_academico.group_ano_escolar.nu_id_pais.caracteristicas.label
                :placeholder=fields_academico.group_ano_escolar.nu_id_pais.caracteristicas.placeholder
                prepend-icon=""
                outlined
                :disabled=!fields_academico.isEditing                            
                ></v-autocomplete>
            </validation-provider>
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"
                :rules=fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.required
              >
                <v-text-field
                  v-model="fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_academico.isEditing                            
                ></v-text-field>
              </validation-provider>     
                </template>
                <span>{{fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
              <validation-provider
                    v-slot="{ errors }"
                    name="fields_academico.group_ano_escolar.fe_inicial.fe_inicial"
                    rules="required"
              >
                      <v-dialog
                        ref="dialog_cita_ini"
                        v-model="modal_dialogo_ini"
                        :return-value.sync="fields_academico.group_ano_escolar.fe_inicial.fe_inicial"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fields_academico.group_ano_escolar.fe_inicial.fe_inicial"
                            label="Fecha de inicio"
                            :error-messages="errors"
                            :prepend-icon=icons.mdiCalendar
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fields_academico.group_ano_escolar.fe_inicial.fe_inicial"
                          :min='fecha_cita_min'
                          :date-format="date => new Date(date).toDateString()" 
                          :formatted-value.sync="fields_academico.group_ano_escolar.fe_inicial.fe_inicial"
                          locale="es"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal_dialogo_ini = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog_cita_ini.save(fields_academico.group_ano_escolar.fe_inicial.fe_inicial)"
                          >
                            Guardar
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
              </validation-provider>     
          </div>  
          <div>
              <validation-provider
                    v-slot="{ errors }"
                    name="fields_academico.group_ano_escolar.fe_final.fe_final"
                    rules="required"
              >
                      <v-dialog
                        ref="dialog_cita"
                        v-model="modal_dialogo"
                        :return-value.sync="fields_academico.group_ano_escolar.fe_final.fe_final"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fields_academico.group_ano_escolar.fe_final.fe_final"
                            label="Fecha fin"
                            :error-messages="errors"
                            :prepend-icon=icons.mdiCalendar
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fields_academico.group_ano_escolar.fe_final.fe_final"
                          :min='fecha_cita_min'
                          :date-format="date => new Date(date).toDateString()" 
                          :formatted-value.sync="fields_academico.group_ano_escolar.fe_final.fe_final"
                          locale="es"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal_dialogo = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog_cita.save(fields_academico.group_ano_escolar.fe_final.fe_final)"
                          >
                            Guardar
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
              </validation-provider>     
          </div>  

          </ValidationObserver>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_PAIS } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'EspecialidadDatosBasicos',
  data () {
    return {
        modal: false,                 
        search:null,
        isLoading: false,
        tipo_persona: [],
        modal_dialogo:false,
        modal_dialogo_ini:false,
        codigosPais: []                
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }         
  },     
  computed: {
    ...mapGetters(['fields_academico']),
    listaPaises () {
        return this.codigosPais.map(entry => {
            const Description = entry.tx_denominacion.length > this.descriptionLimit
            ? entry.tx_denominacion.slice(0, this.descriptionLimit) + '...'
            : entry.tx_denominacion

            return Object.assign({}, entry, { Description })
        })
    },             

    fecha_cita_min() {
    //const [month, day, year] = date.split('/')
    //return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`

       return new Date().toISOString().slice(0,10);
    },    
  },
  props: {
    send_especialidad: Object
  },
  components: {

  },    
  methods: {
    searchPais(q) {
    this.$store.dispatch(LIST_PAIS, {q: q}).then(response => {
        this.codigosPais = response.rest.items
    }, error => {
        this.$store.commit(SET_ERROR, error.statusText)
    })      
    },      
    async guardar () {
        const isValid = await this.$refs.datos_basicos.validate()
        if (!isValid) {
            return true
        }
        this.$emit("guardar_especialidad_datos_basicos", JSON.stringify(this.fields_academico.group_ano_escolar));
    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

    this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          




  },
  
  mounted() {
    this.searchPais()
  },
    
}
</script>