var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_academico.group_grado.json_group_grado.json_group_grado,"sort-by":"nu_id_grado"},scopedSlots:_vm._u([{key:"item.grado",fn:function(ref){
var item = ref.item;
return _vm._l((item.grado),function(item_grado,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item_grado.tx_nombre_grado)+" ")])})}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Crear Grado / Año / Nivel ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano","rules":_vm.fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.label,"disabled":_vm.fields_academico.isEditing},model:{value:(_vm.fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_ano_escolar.tx_nombre_ano, "tx_nombre_ano", $$v)},expression:"fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('validation-provider',{attrs:{"name":"fields_academico.group_grado.json_nivel.json_nivel","rules":_vm.fields_academico.group_grado.json_nivel.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.fields_academico.group_plan_estudio.json_group_plan_estudio.json_group_plan_estudio,"item-text":"tx_nivel","item-value":"nu_id_nivel_educativo","placeholder":_vm.fields_academico.group_grado.json_nivel.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_academico.group_grado.json_nivel.caracteristicas.label,"outlined":"","return-object":"","disabled":!_vm.fields_academico.isEditing},on:{"change":_vm.showPlan},model:{value:(_vm.fields_academico.group_grado.json_nivel.json_nivel),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_grado.json_nivel, "json_nivel", $$v)},expression:"fields_academico.group_grado.json_nivel.json_nivel"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"fields_academico.group_grado.co_plan_estudio.co_plan_estudio","rules":_vm.fields_academico.group_grado.co_plan_estudio.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_academico.group_grado.co_plan_estudio.caracteristicas.label,"disabled":_vm.fields_academico.isEditing},model:{value:(_vm.fields_academico.group_grado.co_plan_estudio.co_plan_estudio),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_grado.co_plan_estudio, "co_plan_estudio", $$v)},expression:"fields_academico.group_grado.co_plan_estudio.co_plan_estudio"}})]}}])})],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_academico.group_grado.tx_nombre_grado.tx_nombre_grado","rules":_vm.fields_academico.group_grado.tx_nombre_grado.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_academico.group_grado.tx_nombre_grado.caracteristicas.label,"disabled":!_vm.fields_academico.isEditing},model:{value:(_vm.fields_academico.group_grado.tx_nombre_grado.tx_nombre_grado),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_grado.tx_nombre_grado, "tx_nombre_grado", $$v)},expression:"fields_academico.group_grado.tx_nombre_grado.tx_nombre_grado"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_academico.group_grado.tx_nombre_grado.caracteristicas.tooltip)+" ")])])],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Esta seguro de borrar este registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Si")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }