<template>
    <div>  
        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="send_datos.list_anos"
                    :items-per-page="5"
                    :search="search"        
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Cargando... Por favor espere"                    
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="asociar_institucion(item)"
                        >
                          {{ icons.mdiSchool }}
                        </v-icon>
        <!--                <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
                        <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                        <!-- name -->
                        <template #[`item.tx_nombre_ano`]="{item}">
                          <div class="d-flex flex-column">
                            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_nombre_ano }}</span>
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>          
                  </v-data-table>
              </div>
    </div>

        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogAnoEscolarInstitucion" :value="true" @input="dialogAnoEscolarInstitucion = false"                   
            >
            <v-card>
              <v-container>

                <!--<div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Historia clínica: </span><span><strong>{{ fields_especialidad.group_especialidad.tx_especialidad.tx_especialidad }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_especialidad.group_especialidad.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                
-->
                <div class="w-full mx-auto my-auto">

                    <AnoEscolarInstitucion titulo="Editar institución" @guardar_anoescolar_institucion="return_anoescolar_institucion" :send_datos="send_datos"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus,
  mdiSchool

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
import { LIST_ANOS } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import AnoEscolarInstitucion from "@/views/configuracion/Asociar/AnoEscolarInstitucion.vue";

export default {
    name: 'ListarAcademico',
    data() {
        return {
            loading: false,
            dialogAnoEscolarInstitucion: false, 
            search: '',
            titulo: 'Especialidad',
            especialidad_01: [],
            headers: [
              {
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'nu_id_ano_escolar',
              },          
              {
                text: 'País',
                sortable: true,
                value: 'tx_denominacion',
              },          
              {
                text: 'Nombre del año',
                sortable: true,
                value: 'tx_nombre_ano',
              },          
              { 
                text: 'Fecha de inicio', 
                value: 'fe_inicial' 
              },
              { 
                text: 'Fecha fin', 
                value: 'fe_final' 
              },
              { 
                text: 'Estado', 
                value: 'co_estatus' 
              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },          
            ],            

        }
    },
    props: {
        send_datos: Object,
    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus,
            mdiSchool
        });  


        return {
        icons
        }
    },
    methods: {
    asociar_institucion (item) {
      console.log('Editar id: ', item) // someValue
      this.send_datos.AnoEscolar = item
      this.send_datos.operacion = 'ASOCIAR'
      this.dialogAnoEscolarInstitucion = true

    },      
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.especialidad = id
      this.send_datos.operacion = 'EDITAR'
      this.dialogAnoEscolarInstitucion = true
    },
    return_anoescolar_institucion(msg) {
      console.log('Parametro Lista Academica: ', msg)    
        this.$emit("guardar_anoescolar_institucion", msg);
        this.dialogAnoEscolarInstitucion = false
    },    
    searchAnos(q) {

      this.loading = true
          this.$store.dispatch(LIST_ANOS, {q: q}).then(response => {
              this.send_datos.list_anos = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          }).finally(() => (
            setTimeout(() => {
                this.loading = false
              },3000)               
        ))        
      
    },
}, 
    components: {
      AnoEscolarInstitucion,
    },
    mounted() {
      this.searchAnos({})
    },
    watch: {
    },    


}
</script>