 <template>
  <div class="w-full p-2">
      <div>
        <AnoEscolarInstitucion :send_datos="send_datos" @guardar_anoescolar_institucion="return_anoescolar_institucion"></AnoEscolarInstitucion>
      </div>

  </div>
</template>

<script>
import AnoEscolarInstitucion from "@/components/configuracion/Asociar/AnoEscolarInstitucion.vue";
export default {
  name: 'AnoEscolar',
  data () {
    return {
      titulo: 'Año Escolar',
      datos_usuario: [],
      send_usuario: {},

    }
  },
  props: {
      send_datos: Object,
  },  
  setup() {
  },  
  computed: {
  },
  components: {
    AnoEscolarInstitucion
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    return_anoescolar_institucion (msg) {
        //this.buscarPersona('params')

      this.$emit("guardar_anoescolar_institucion", msg);

    },        

  },
  mounted () {
    //this.searchAnoEscolar({})
    /*  this.$store.dispatch(READ_USUARIO, {}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },

}
</script>
