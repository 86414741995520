 <template>
  <div class="w-full p-2">

      <div>
        <AnoEscolarOpciones  :send_datos="send_datos"></AnoEscolarOpciones>
      </div>

  </div>
</template>

<script>
import AnoEscolarOpciones from "@/views/configuracion/AnoEscolar/AnoEscolarOpciones.vue";


export default {
  name: 'AnoEscolar',
  data () {
    return {
      titulo: 'Academia',
      datos_academia: [],
      send_datos: {
        list_anos: []
      },

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    AnoEscolarOpciones
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {


  },
  mounted () {

  },

}
</script>
