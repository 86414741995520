<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
        <div class="">
            <div>              
                <v-data-table
                  :headers="headers"
                  :items="fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion"
                  sort-by="json_plan_estudio.co_plan_estudio"
                  class="elevation-1 mb-10"
                  :loading="loading"
                  loading-text="Cargando... Por favor espere"                    
                >
                  <template v-slot:item.json_institucion="{ item }">
                      {{ item.json_institucion.tx_razon_social }}
                  </template>     
                  <template v-slot:item.json_plan_estudio="{ item }">
                      {{ item.json_plan_estudio.co_plan_estudio }}
                  </template>     


                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Asociar institución                                  
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <ValidationObserver ref="datos_basicos_plan_institucion">

                                        <div>
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">

                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_asociaciones.group_ano_institucion.tx_nombre_ano.tx_nombre_ano"
                                              :rules=fields_asociaciones.group_ano_institucion.tx_nombre_ano.caracteristicas.required
                                            >
                                              <v-text-field
                                                v-model="fields_asociaciones.group_ano_institucion.tx_nombre_ano.tx_nombre_ano"
                                                outlined
                                                class="input-group--focused text-lg pt-2"
                                                :error-messages="errors"
                                                :label=fields_asociaciones.group_ano_institucion.tx_nombre_ano.caracteristicas.label
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled=fields_asociaciones.isEditing                            
                                              ></v-text-field>
                                            </validation-provider>     
                                              </template>
                                              <span>{{fields_asociaciones.group_ano_institucion.tx_nombre_ano.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                
                                        </div>
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_asociaciones.group_ano_institucion.json_institucion.json_institucion"
                                            :rules=fields_asociaciones.group_ano_institucion.json_institucion.caracteristicas.required
                                          >
                                                  <v-select
                                                    v-model="fields_asociaciones.group_ano_institucion.json_institucion.json_institucion"
                                                    :items="list_instituciones"
                                                    item-text="tx_razon_social"
                                                    item-value="nu_id_institucion"
                                                    class="input-group--focused text-lg"
                                                    :placeholder=fields_asociaciones.group_ano_institucion.json_institucion.caracteristicas.placeholder
                                                    :error-messages="errors"
                                                    :label=fields_asociaciones.group_ano_institucion.json_institucion.caracteristicas.label
                                                    outlined
                                                    return-object
                                                    :disabled=!fields_asociaciones.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div>  
                                        <div>
                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_asociaciones.group_ano_institucion.json_nivel.json_nivel"
                                              :rules=fields_asociaciones.group_ano_institucion.json_nivel.caracteristicas.required
                                            >
                                              <v-select
                                                v-model="fields_asociaciones.group_ano_institucion.json_nivel.json_nivel"
                                                :items="list_niveles"
                                                item-text="tx_nivel"
                                                item-value="nu_id_nivel_educativo"
                                                class="input-group--focused text-lg"
                                                :placeholder=fields_asociaciones.group_ano_institucion.json_nivel.caracteristicas.placeholder
                                                :error-messages="errors"
                                                :label=fields_asociaciones.group_ano_institucion.json_nivel.caracteristicas.label
                                                outlined
                                                return-object
                                                @change="setPlanes"
                                                :disabled=!fields_asociaciones.isEditing
                                              ></v-select>  
                                            </validation-provider>     
                                        </div>
                                        <div>
                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_asociaciones.group_ano_institucion.json_plan_estudio.json_plan_estudio"
                                              :rules=fields_asociaciones.group_ano_institucion.json_plan_estudio.caracteristicas.required
                                            >
                                              <v-select
                                                v-model="fields_asociaciones.group_ano_institucion.json_plan_estudio.json_plan_estudio"
                                                :items="list_plan_institucion"
                                                item-text="tx_descripcion"
                                                item-value="nu_id_plan"
                                                class="input-group--focused text-lg"
                                                :placeholder=fields_asociaciones.group_ano_institucion.json_plan_estudio.caracteristicas.placeholder
                                                :error-messages="errors"
                                                :label=fields_asociaciones.group_ano_institucion.json_plan_estudio.caracteristicas.label
                                                outlined
                                                return-object
                                                @change="setGrados"
                                                :disabled=!fields_asociaciones.isEditing
                                              ></v-select>                      
                                            </validation-provider>     
                                        </div>  
                                        
                                        <div>
                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_asociaciones.group_ano_institucion.json_grados.json_grados"
                                              :rules=fields_asociaciones.group_ano_institucion.json_grados.caracteristicas.required
                                            >
                                              <v-select
                                              v-model='fields_asociaciones.group_ano_institucion.json_grados.json_grados'
                                              :items="list_grados_institucion"
                                              item-text="tx_nombre_grado"
                                              item-value="nu_id_grado"
                                                class="input-group--focused text-lg"
                                                :placeholder=fields_asociaciones.group_ano_institucion.json_plan_estudio.caracteristicas.placeholder
                                                :error-messages="errors"
                                              :label=fields_asociaciones.group_ano_institucion.json_grados.caracteristicas.label
                                                outlined
                                                multiple
                                                return-object
                                                @change="setAreas"
                                                :disabled=!fields_asociaciones.isEditing
                                              ></v-select>                      

                                            </validation-provider>     
                                        </div>  
<!--                                        
                                        <div>
                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_asociaciones.group_ano_institucion.json_areas.json_areas"
                                              :rules=fields_asociaciones.group_ano_institucion.json_areas.caracteristicas.required
                                            >
                                              <v-autocomplete
                                              v-model='fields_asociaciones.group_ano_institucion.json_areas.json_areas'
                                              :items="list_areas"
                                              :error-messages="errors"
                                              chips
                                              outlined
                                              color="blue-grey lighten-2"
                                              :label=fields_asociaciones.group_ano_institucion.json_areas.caracteristicas.label
                                              item-text="tx_descripcion"
                                              item-value="nu_id_area_formacion"
                                              multiple
                                              return-object
                                              :disabled=!fields_asociaciones.isEditing                            
                                              >
                                              <template v-slot:selection="data">
                                                  <v-chip
                                                  v-bind="data.attrs"
                                                  :input-value="data.selected"
                                                  close
                                                  @click="data.select"
                                                  @click:close="remove(data.item)"
                                                  >
                                                  {{ data.item.tx_descripcion }}
                                                  </v-chip>
                                              </template>

                                              </v-autocomplete>
                                            </validation-provider>     
                                        </div>  
                                        -->
                                  </ValidationObserver>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro de borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  


                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cerrar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
            </div>
            <!-- Fin Boton de Navegacion -->    

       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { LIST_ANOS, LIST_INSTITUCION, LIST_ANO_NIVELES, LIST_PLANES,LIST_GRADOS,LIST_INSTITUCION_NIVELES, CREATE_ASOCIAR_INSTITUCION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'AnoEscolarInstitucion',
  data () {
    return {
          loading: false,
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
        list_especialidades: [],
        list_instituciones: [],
        list_niveles: [],
        list_planes: [],
        list_plan_institucion: [],
        list_grados_institucion: [],
        list_areas: [],
        fields_asociaciones_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_institucion: '',
          json_especialidad: '',
        },  
        defaultItem: {
          json_institucion: [],
          json_especialidad: [],
          caracteristicas: {
            nombre: 'json_institucion',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        institucion_especialidad: {},
        items_data_table: [],   
        items_niveles: {
          json_ano_institucion: [],
          tx_nivel: '',
          tx_etiqueta: '',
          tx_descripcion: '',
          nu_id_padre: '',
          json_institucion: [],
        },   

        headers: [
            {
              text: 'Año escolar',
              align: 'start',
              sortable: true,
              value: 'tx_nombre_ano',
            },
            { text: 'Institución', value: 'json_institucion' },        
            { text: 'Nivel', value: 'tx_nivel' },        
            { text: 'Plan de estudio', value: 'json_plan_estudio', sortable: true },        
            { text: 'Nivel/Grado/Año', value: 'tx_nombre_grado' },                    
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_asociaciones']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {
    NotificacionInterna
  },    
  methods: {
    searchAnos(params) {
      console.log('Parametros año escolar: ', params)
      this.loading = true
      setTimeout(() => {
          this.$store.dispatch(LIST_ANOS, {q: params}).then(response => {
              this.send_datos.list_anos = response.rest.items
              this.loading = false
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })        
        },3000)           
    },    
      setPlanes(value) {
        //  trigger a mutation, or dispatch an action  
        this.searchPlanes(value.nu_id_ano_escolar)
      },    
      setGrados(value) {
        //  trigger a mutation, or dispatch an action 
         
        this.searchGrados(value)
      },    
      setAreas(value) {
        //  trigger a mutation, or dispatch an action 
         
        this.searchAreas(value)
      },    
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.cleanig()
          //this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion = []
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          //this.institucion_especialidad = Object.assign({}, this.fields_asociaciones.group_ano_institucion.json_institucion.json_institucion)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {
          console.log('this.editedItem: ', this.fields_asociaciones.group_ano_institucion.tx_nombre_ano.tx_nombre_ano)
          /*var aa = {
            json_institucion: this.institucion_especialidad.json_institucion,
            json_especialidad: this.institucion_especialidad.json_especialidad
          }
          */
          //this.fields_asociaciones.json_institucion.json_especialidad = this.institucion_especialidad.json_especialidad
          this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion.push({
            tx_nombre_ano: this.fields_asociaciones.group_ano_institucion.tx_nombre_ano.tx_nombre_ano,
            json_institucion: this.fields_asociaciones.group_ano_institucion.json_institucion.json_institucion,
            json_nivel: this.fields_asociaciones.group_ano_institucion.json_nivel.json_nivel,
            json_plan_estudio: this.fields_asociaciones.group_ano_institucion.json_plan_estudio.json_plan_estudio,
            json_grados: this.fields_asociaciones.group_ano_institucion.json_grados.json_grados,
            json_areas: this.fields_asociaciones.group_ano_institucion.json_areas.json_areas,
            nu_id_ano_escolar: this.send_datos.AnoEscolar.nu_id_ano_escolar,
            nu_id_institucion: this.fields_asociaciones.group_ano_institucion.json_institucion.json_institucion.nu_id_institucion,
            nu_id_nivel_educativo: this.fields_asociaciones.group_ano_institucion.json_nivel.json_nivel.nu_id_nivel_educativo,
            tx_nivel: this.fields_asociaciones.group_ano_institucion.json_nivel.json_nivel.tx_nivel,
            nu_id_plan: this.fields_asociaciones.group_ano_institucion.json_plan_estudio.json_plan_estudio.nu_id_plan,
            nu_id_grado: this.fields_asociaciones.group_ano_institucion.json_grados.json_grados.nu_id_grado,
            tx_nombre_grado: this.fields_asociaciones.group_ano_institucion.json_grados.json_grados.tx_nombre_grado,
          })


            //this.buscarPersona('params')

            const formData = new FormData()
            formData.append('ano_escolar_institucion', JSON.stringify(this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_ASOCIAR_INSTITUCION, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "La institución " + JSON.parse(datos.datos_ano_escolar_institucion).tx_razon_social + " fue asociada satisfactoriamente"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_academico));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("actualizar_datos_academico", this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion);              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             

              })  

          /// hasta aca optimizar


          //this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion = this.items_data_table
          console.log('this.editedItem items_data_table: ', this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion)
          //this.fields_asociaciones.group_ano_institucion.json_institucion.json_institucion.push(this.institucion_especialidad.json_institucion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        //this.searchInstitucion(item.json_institucion.nu_id_institucion)
        this.editedIndex = this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion.indexOf(item)
        const claves_primarias = {}
        claves_primarias.tx_nombre_ano = 'tx_nombre_ano'
        for (const property in item) {
            //console.log('item: ', property)
            if (claves_primarias[property] != property) {
              this.fields_asociaciones.group_ano_institucion[property][property] = item[property]
            }               
        }

        this.dialog = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_ano_institucion = 'json_ano_institucion'

        for (const property in this.fields_asociaciones.group_ano_institucion) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_asociaciones.group_ano_institucion[property][property] = []
              } else {
                this.fields_asociaciones.group_ano_institucion[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        console.log('deleteItem: ', item)
        this.editedIndex = this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchEspecialidad() {
          this.list_especialidades = JSON.parse(this.institucion_especialidad.json_institucion.json_especialidad)
    },    
    searchInstituciones(q) {
      this.$store.dispatch(LIST_INSTITUCION, {q: q}).then(response => {
          this.list_instituciones = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchNiveles(q) {
      var params = {
        nu_id_ano_escolar: q.nu_id_ano_escolar,
        nu_id_nivel_educativo: this.fields_asociaciones.group_ano_institucion.json_nivel.json_nivel.nu_id_nivel_educativo
      }
      this.$store.dispatch(LIST_ANO_NIVELES, params).then(response => {
          this.list_niveles = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchPlanes(q) {
      var params = {
        nu_id_ano_escolar: q,
        nu_id_nivel_educativo: this.fields_asociaciones.group_ano_institucion.json_nivel.json_nivel.nu_id_nivel_educativo
      }
      console.log('params: ', params)
      this.$store.dispatch(LIST_PLANES, params ).then(response => {
          this.list_plan_institucion = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchGrados(q) {
      var params = {
        nu_id_ano_escolar: q.nu_id_ano_escolar,
        nu_id_nivel_educativo: this.fields_asociaciones.group_ano_institucion.json_nivel.json_nivel.nu_id_nivel_educativo
      }
      console.log('params: ', params)
      this.$store.dispatch(LIST_GRADOS, params ).then(response => {
          this.list_grados_institucion = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchAreas(q) {
      var params = {
        nu_id_ano_escolar: q.nu_id_ano_escolar,
        nu_id_nivel_educativo: this.fields_asociaciones.group_ano_institucion.json_nivel.json_nivel.nu_id_nivel_educativo,
        nu_id_grado: this.fields_asociaciones.group_ano_institucion.json_grados.json_grados.nu_id_grado

      }
      console.log('params: ', params)
      this.$store.dispatch(LIST_ANO_NIVELES, params ).then(response => {
          this.list_areas = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchAnoInstitucion(q) {
      var params = {
        nu_id_ano_escolar: q,
      }
      this.$store.dispatch(LIST_INSTITUCION_NIVELES, params ).then(response => {
          this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion = []
          this.isLoading = true
          for (const item in response.rest.items) {
              this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion.push({
                tx_nombre_ano: response.rest.items[item].tx_nombre_ano,
                nu_id_area_formacion: response.rest.items[item].nu_id_area_formacion,
                tx_nivel: response.rest.items[item].tx_nivel,
                tx_nombre_grado: response.rest.items[item].tx_nombre_grado,                
                tx_descripcion: response.rest.items[item].tx_descripcion,
                json_institucion: {
                  nu_id_institucion: response.rest.items[item].nu_id_institucion, 
                  tx_razon_social: response.rest.items[item].tx_razon_social,
                },
                json_nivel: {
                  nu_id_nivel_educativo: response.rest.items[item].nu_id_nivel_educativo, 
                  tx_nivel: response.rest.items[item].tx_nivel,
                },
                json_plan_estudio: {
                  nu_id_plan: response.rest.items[item].nu_id_plan, 
                  co_plan_estudio: response.rest.items[item].co_plan_estudio
                },
                //json_grados: JSON.parse(response.rest.items[item].json_grados),
                //json_areas: JSON.parse(response.rest.items[item].json_areas),


              }
              )        

          }

          //this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion = response.rest.items          
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      }).finally(() => (
        setTimeout(() => {
            this.isLoading = false
          },3000)               
        ))      
    },

    remove (item) {
      var index = this.institucion_especialidad.json_especialidad.findIndex(i => i.nu_id_especialidad === item.nu_id_especialidad);
      if (index >= 0) this.institucion_especialidad.json_especialidad.splice(index, 1)
    },
      
    async guardar () {
          console.log('Guardar: ')
          var isValid = false
          if (this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion) {
            isValid = true
          }
          if (isValid) {
            this.$emit("guardar_anoescolar_institucion", JSON.stringify(this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion));
          }          


        // const isValid = await this.$refs.datos_basicos_plan_institucion.validate()
        // if (!isValid) {
        //     return true
        // }
    },              
    async cancelar () {
    //this.loading4 = true 
        this.$emit("guardar_anoescolar_institucion", JSON.stringify(this.fields_asociaciones));

    //this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          


  },
  
  mounted() {
    //this.searchPerfiles();
    this.searchAnos({ })
    this.fields_asociaciones.group_ano_institucion.tx_nombre_ano.tx_nombre_ano = this.send_datos.AnoEscolar.tx_nombre_ano
    this.searchInstituciones()
    this.searchNiveles({
        nu_id_ano_escolar: this.send_datos.AnoEscolar.nu_id_ano_escolar, 
        nu_id_nivel_educativo: ''
        }
    )
    this.searchAnoInstitucion(this.send_datos.AnoEscolar.nu_id_ano_escolar)
    //this.searchAreas('oficial')
    if (this.send_datos.operacion === 'EDITAR') {
        //this.fields_asociaciones.group_ano_institucion.json_institucion.json_institucion = JSON.parse(this.send_datos.usuario.json_institucion)
        //this.fields_asociaciones.group_ano_institucion.json_especialidad.json_especialidad = JSON.parse(this.send_datos.usuario.json_especialidad)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_asociaciones[prop] === 'object') {
          //           if (this.fields_asociaciones[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_asociaciones[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_asociaciones[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_asociaciones[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.list_especialidades = JSON.parse(this.fields_asociaciones.group_ano_institucion.json_institucion.json_institucion[0].json_especialidad)
        //this.fields_asociaciones.group_ano_institucion.json_ano_institucion.json_ano_institucion = []
        this.fields_asociaciones.isEditing = true
    }           
  },
    
}
</script>