<template>
  <div class="">
        <div class="">
            <div>              
                <v-data-table
                  :headers="headers"
                  :items="fields_academico.group_plan_estudio.json_group_plan_estudio.json_group_plan_estudio"
                  sort-by="nu_id_nivel_educativo"
                  class="elevation-1 mb-10"

                >
<!--                
                  <template v-slot:item.json_nivel="{ item }">
                      {{ item.json_nivel.tx_nivel }}
                  </template>     
                  <template v-slot:item.json_nivel_padre="{ item }">
                      {{ item.json_nivel_padre.tx_nivel }}
                  </template>     
-->

                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Crear Plan
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                        <div>
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">

                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"
                                              :rules=fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.required
                                            >
                                              <v-text-field
                                                v-model="fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"
                                                outlined
                                                class="input-group--focused text-lg pt-2"
                                                :error-messages="errors"
                                                :label=fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.label
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled=fields_academico.isEditing                            
                                              ></v-text-field>
                                            </validation-provider>     
                                              </template>
                                              <span>{{fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                
                                        </div>
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_academico.group_plan_estudio.json_nivel.json_nivel"
                                            :rules=fields_academico.group_plan_estudio.json_nivel.caracteristicas.required
                                          >
                                                  <v-select
                                                    v-model="fields_academico.group_plan_estudio.json_nivel.json_nivel"
                                                    :items="fields_academico.group_niveles.json_group_niveles.json_group_niveles"
                                                    item-text="tx_nivel"
                                                    item-value="nu_id_nivel_educativo"
                                                    class="input-group--focused text-lg"
                                                    :placeholder=fields_academico.group_plan_estudio.json_nivel.caracteristicas.placeholder
                                                    :error-messages="errors"
                                                    :label=fields_academico.group_plan_estudio.json_nivel.caracteristicas.label
                                                    outlined
                                                    return-object
                                                    :disabled=!fields_academico.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div>  
                                        <div>
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">
                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  name="fields_academico.group_plan_estudio.co_plan_estudio.co_plan_estudio"
                                                  :rules=fields_academico.group_plan_estudio.co_plan_estudio.caracteristicas.required
                                                >
                                                  <v-text-field
                                                    v-model="fields_academico.group_plan_estudio.co_plan_estudio.co_plan_estudio"
                                                    outlined
                                                    class="input-group--focused text-lg pt-2"
                                                    :error-messages="errors"
                                                    :label=fields_academico.group_plan_estudio.co_plan_estudio.caracteristicas.label
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled=!fields_academico.isEditing                            
                                                  ></v-text-field>
                                                </validation-provider>     
                                              </template>
                                              <span>{{fields_academico.group_plan_estudio.co_plan_estudio.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                
                                        </div>  
                                        <div>
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">
                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  name="fields_academico.group_plan_estudio.tx_descripcion.tx_descripcion"
                                                  :rules=fields_academico.group_plan_estudio.tx_descripcion.caracteristicas.required
                                                >
                                                  <v-textarea
                                                    v-model="fields_academico.group_plan_estudio.tx_descripcion.tx_descripcion"
                                                    outlined
                                                    class="input-group--focused text-lg pt-2"
                                                    :error-messages="errors"
                                                    :label=fields_academico.group_plan_estudio.tx_descripcion.caracteristicas.label
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled=!fields_academico.isEditing                            
                                                  ></v-textarea>
                                                </validation-provider>     
                                              </template>
                                              <span>{{fields_academico.group_plan_estudio.tx_descripcion.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                
                                        </div>  


                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro de borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { LIST_INSTITUCION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'PlanEstudio',
  data () {
    return {
        list_especialidades: [],
        fields_academico_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_institucion: '',
          json_especialidad: '',
        },  
        defaultItem: {
          json_institucion: [],
          json_especialidad: [],
          caracteristicas: {
            nombre: 'json_institucion',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        institucion_especialidad: {},
        items_data_table: [],   
        items_niveles: {
          json_group_niveles: [],
          json_nivel: [],
          tx_etiqueta: '',
          tx_descripcion: '',
          nu_id_padre: '',
          json_nivel_padre: [],
        },   

        headers: [
            {
              text: 'Año escolar',
              align: 'start',
              sortable: false,
              value: 'tx_nombre_ano',
            },
            { text: 'Nivel', value: 'tx_nivel' },        
            { text: 'Código plan', value: 'co_plan_estudio' },        
            { text: 'Descripción', value: 'tx_descripcion' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_academico']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {

  },    
  methods: {
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.cleanig()
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {
          console.log('this.editedItem: ', this.fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano)
          /*var aa = {
            json_institucion: this.institucion_especialidad.json_institucion,
            json_especialidad: this.institucion_especialidad.json_especialidad
          }
          */
          //this.fields_academico.json_institucion.json_especialidad = this.institucion_especialidad.json_especialidad

          this.fields_academico.group_plan_estudio.json_group_plan_estudio.json_group_plan_estudio.push({
            tx_nombre_ano: this.fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano,
            nu_id_nivel_educativo: this.fields_academico.group_plan_estudio.json_nivel.json_nivel.nu_id_nivel_educativo,
            tx_nivel: this.fields_academico.group_plan_estudio.json_nivel.json_nivel.tx_nivel,
            co_plan_estudio: this.fields_academico.group_plan_estudio.co_plan_estudio.co_plan_estudio,
            tx_descripcion: this.fields_academico.group_plan_estudio.tx_descripcion.tx_descripcion,
            nu_id_plan: this.fields_academico.group_plan_estudio.json_group_plan_estudio.json_group_plan_estudio.length + 1,
          })
          //this.fields_academico.group_niveles.json_group_niveles.json_group_niveles = this.items_data_table
          console.log('this.editedItem items_data_table: ', this.fields_academico.group_niveles.json_group_niveles.json_group_niveles)
          //this.fields_academico.group_niveles.json_institucion.json_institucion.push(this.institucion_especialidad.json_institucion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        console.log('item: ', item)
        //this.searchInstitucion(item.json_institucion.nu_id_institucion)
        this.editedIndex = this.fields_academico.group_niveles.json_group_niveles.json_group_niveles.indexOf(item)
        const claves_primarias = {}
        claves_primarias.tx_nombre_ano = 'tx_nombre_ano'
        for (const property in item) {
            //console.log('item: ', property)
            if (claves_primarias[property] != property) {
              this.fields_academico.group_niveles[property][property] = item[property]
            }               
        }

        this.dialog = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_group_plan_estudio = 'json_group_plan_estudio'

        for (const property in this.fields_academico.group_plan_estudio) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_academico.group_plan_estudio[property][property] = []
              } else {
                this.fields_academico.group_plan_estudio[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        this.editedIndex = this.fields_academico.group_plan_estudio.json_group_plan_estudio.json_group_plan_estudio.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_academico.group_plan_estudio.json_group_plan_estudio.json_group_plan_estudio.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchEspecialidad() {
          this.list_especialidades = JSON.parse(this.institucion_especialidad.json_institucion.json_especialidad)
    },    
    searchInstitucion(q) {
      console.log('searchInstitucion 1: ', q)
      this.$store.dispatch(LIST_INSTITUCION, {nu_id_institucion: q}).then(response => {
          console.log('searchInstitucion 2: ', response.rest.items)
          this.list_especialidades = JSON.parse(response.rest.items[0].json_especialidad)
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
    remove (item) {
      var index = this.institucion_especialidad.json_especialidad.findIndex(i => i.nu_id_especialidad === item.nu_id_especialidad);
      if (index >= 0) this.institucion_especialidad.json_especialidad.splice(index, 1)
    },    

  },
  
  mounted() {
    //this.searchPerfiles();
    if (this.send_datos.operacion === 'EDITAR') {
        //this.fields_academico.group_niveles.json_institucion.json_institucion = JSON.parse(this.send_datos.usuario.json_institucion)
        //this.fields_academico.group_niveles.json_especialidad.json_especialidad = JSON.parse(this.send_datos.usuario.json_especialidad)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_academico[prop] === 'object') {
          //           if (this.fields_academico[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_academico[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_academico[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_academico[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.list_especialidades = JSON.parse(this.fields_academico.group_niveles.json_institucion.json_institucion[0].json_especialidad)
        //this.fields_academico.group_niveles.json_group_niveles.json_group_niveles = []
        this.fields_academico.isEditing = true
    }           
  },
    
}
</script>