var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('ValidationObserver',{ref:"datos_basicos"},[_c('div',[_c('validation-provider',{attrs:{"name":"fields_academico.group_ano_escolar.nu_id_pais.nu_id_pais","rules":_vm.fields_academico.group_ano_escolar.nu_id_pais.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listaPaises,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_denominacion","item-value":"nu_id_pais","label":_vm.fields_academico.group_ano_escolar.nu_id_pais.caracteristicas.label,"placeholder":_vm.fields_academico.group_ano_escolar.nu_id_pais.caracteristicas.placeholder,"prepend-icon":"","outlined":"","disabled":!_vm.fields_academico.isEditing},model:{value:(_vm.fields_academico.group_ano_escolar.nu_id_pais.nu_id_pais),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_ano_escolar.nu_id_pais, "nu_id_pais", $$v)},expression:"fields_academico.group_ano_escolar.nu_id_pais.nu_id_pais"}})]}}])})],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano","rules":_vm.fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.label,"disabled":!_vm.fields_academico.isEditing},model:{value:(_vm.fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_ano_escolar.tx_nombre_ano, "tx_nombre_ano", $$v)},expression:"fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('validation-provider',{attrs:{"name":"fields_academico.group_ano_escolar.fe_inicial.fe_inicial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-dialog',{ref:"dialog_cita_ini",attrs:{"return-value":_vm.fields_academico.group_ano_escolar.fe_inicial.fe_inicial,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_inicial, "fe_inicial", $event)},"update:return-value":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_inicial, "fe_inicial", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de inicio","error-messages":errors,"prepend-icon":_vm.icons.mdiCalendar},model:{value:(_vm.fields_academico.group_ano_escolar.fe_inicial.fe_inicial),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_ano_escolar.fe_inicial, "fe_inicial", $$v)},expression:"fields_academico.group_ano_escolar.fe_inicial.fe_inicial"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.modal_dialogo_ini),callback:function ($$v) {_vm.modal_dialogo_ini=$$v},expression:"modal_dialogo_ini"}},[_c('v-date-picker',{attrs:{"min":_vm.fecha_cita_min,"date-format":function (date) { return new Date(date).toDateString(); },"formatted-value":_vm.fields_academico.group_ano_escolar.fe_inicial.fe_inicial,"locale":"es"},on:{"update:formattedValue":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_inicial, "fe_inicial", $event)},"update:formatted-value":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_inicial, "fe_inicial", $event)}},model:{value:(_vm.fields_academico.group_ano_escolar.fe_inicial.fe_inicial),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_ano_escolar.fe_inicial, "fe_inicial", $$v)},expression:"fields_academico.group_ano_escolar.fe_inicial.fe_inicial"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal_dialogo_ini = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog_cita_ini.save(_vm.fields_academico.group_ano_escolar.fe_inicial.fe_inicial)}}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"fields_academico.group_ano_escolar.fe_final.fe_final","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-dialog',{ref:"dialog_cita",attrs:{"return-value":_vm.fields_academico.group_ano_escolar.fe_final.fe_final,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_final, "fe_final", $event)},"update:return-value":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_final, "fe_final", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha fin","error-messages":errors,"prepend-icon":_vm.icons.mdiCalendar},model:{value:(_vm.fields_academico.group_ano_escolar.fe_final.fe_final),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_ano_escolar.fe_final, "fe_final", $$v)},expression:"fields_academico.group_ano_escolar.fe_final.fe_final"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.modal_dialogo),callback:function ($$v) {_vm.modal_dialogo=$$v},expression:"modal_dialogo"}},[_c('v-date-picker',{attrs:{"min":_vm.fecha_cita_min,"date-format":function (date) { return new Date(date).toDateString(); },"formatted-value":_vm.fields_academico.group_ano_escolar.fe_final.fe_final,"locale":"es"},on:{"update:formattedValue":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_final, "fe_final", $event)},"update:formatted-value":function($event){return _vm.$set(_vm.fields_academico.group_ano_escolar.fe_final, "fe_final", $event)}},model:{value:(_vm.fields_academico.group_ano_escolar.fe_final.fe_final),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_ano_escolar.fe_final, "fe_final", $$v)},expression:"fields_academico.group_ano_escolar.fe_final.fe_final"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal_dialogo = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog_cita.save(_vm.fields_academico.group_ano_escolar.fe_final.fe_final)}}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }