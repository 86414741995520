<template>
  <div class="">
        <div class="">
            <div>              
                <v-data-table
                  :headers="headers"
                  :items="fields_academico.group_area_formacion.json_group_area_formacion.json_group_area_formacion"
                  sort-by="tx_nombre_ano"
                  class="elevation-1 mb-10"
                >
                  <template v-slot:item.nivel_grado="{ item }">
                      <div class="inset-x-0 top-0 h-16 " v-for="(item_grado, index) in item.grado" :key="index">
                            {{ item_grado.tx_nombre_grado }}
                      </div>
                  </template>                   
                  <template v-slot:item.grado="{ item }">
                      <div v-for="(item_grado, index) in item.grado" :key="index">
                        <div v-for="(item_area, index) in item_grado.area" :key="index">
                            {{ item_area.tx_descripcion }}
                        </div>
                      </div>
                  </template>                   
<!--
                  <template v-slot:item.json_grado="{ item }">
                      {{ item.json_grado.tx_nombre_grado }}
                  </template>     
-->                  
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Crear Área
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                        <div>
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">

                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"
                                              :rules=fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.required
                                            >
                                              <v-text-field
                                                v-model="fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano"
                                                outlined
                                                class="input-group--focused text-lg pt-2"
                                                :error-messages="errors"
                                                :label=fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.label
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled=fields_academico.isEditing                            
                                              ></v-text-field>
                                            </validation-provider>     
                                              </template>
                                              <span>{{fields_academico.group_ano_escolar.tx_nombre_ano.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                
                                        </div>
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_academico.group_area_formacion.json_nivel.json_nivel"
                                            :rules=fields_academico.group_area_formacion.json_nivel.caracteristicas.required
                                          >
                                                  <v-select
                                                    v-model="fields_academico.group_area_formacion.json_nivel.json_nivel"
                                                    :items="fields_academico.group_grado.json_group_grado.json_group_grado"
                                                    item-text="tx_nivel"
                                                    item-value="nu_id_nivel_educativo"
                                                    class="input-group--focused text-lg"
                                                    :placeholder=fields_academico.group_area_formacion.json_nivel.caracteristicas.placeholder
                                                    :error-messages="errors"
                                                    :label=fields_academico.group_area_formacion.json_nivel.caracteristicas.label
                                                    outlined
                                                    return-object
                                                    @change="showPlan"
                                                    :disabled=!fields_academico.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div>  

                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_academico.group_area_formacion.co_plan_estudio.co_plan_estudio"
                                            :rules=fields_academico.group_area_formacion.co_plan_estudio.caracteristicas.required
                                          >
                                              <v-text-field
                                                v-model="fields_academico.group_area_formacion.co_plan_estudio.co_plan_estudio"
                                                outlined
                                                class="input-group--focused text-lg pt-2"
                                                :error-messages="errors"
                                                :label=fields_academico.group_area_formacion.co_plan_estudio.caracteristicas.label
                                                :disabled=fields_academico.isEditing                            
                                              ></v-text-field>
                                          </validation-provider>                    
                                        </div>  
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_academico.group_area_formacion.json_grados.json_grados"
                                            :rules=fields_academico.group_area_formacion.json_grados.caracteristicas.required
                                          >
                                                  <v-select
                                                    v-model="fields_academico.group_area_formacion.json_grados.json_grados"
                                                    :items="fields_academico.group_area_formacion.json_nivel.json_nivel.grado"
                                                    item-text="tx_nombre_grado"
                                                    item-value="nu_id_grado"
                                                    class="input-group--focused text-lg"
                                                    :placeholder=fields_academico.group_area_formacion.json_grados.caracteristicas.placeholder
                                                    :error-messages="errors"
                                                    :label=fields_academico.group_area_formacion.json_grados.caracteristicas.label
                                                    outlined
                                                    return-object
                                                    :disabled=!fields_academico.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div>  
                                        <div>
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_academico.group_area_formacion.json_area.json_area"
                                              :rules=fields_academico.group_area_formacion.json_area.caracteristicas.required
                                          >
                                              <v-autocomplete
                                              v-model='fields_academico.group_area_formacion.json_area.json_area'
                                              :items="list_areas_formacion"
                                              :error-messages="errors"
                                              chips
                                              outlined
                                              color="blue-grey lighten-2"
                                              :label=fields_academico.group_area_formacion.json_area.caracteristicas.label
                                              item-text="tx_descripcion"
                                              item-value="nu_id_area_formacion"
                                              multiple
                                              return-object
                                              :disabled=!fields_academico.isEditing                            
                                              >
                                              <template v-slot:selection="data">
                                                  <v-chip
                                                  v-bind="data.attrs"
                                                  :input-value="data.selected"
                                                  close
                                                  @click="data.select"
                                                  @click:close="remove(data.item)"
                                                  >
                                                  {{ data.item.tx_descripcion }}
                                                  </v-chip>
                                              </template>

                                              </v-autocomplete>


                                          </validation-provider>


                                        </div>  

                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro de borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { LIST_AREAS_FORMACION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'AreaFormacion',
  data () {
    return {
        grupos_area_formacion: {},
        grupos_area_formacion_todos: {},
        list_areas_formacion: [],
        fields_academico_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_institucion: '',
          json_especialidad: '',
        },  
        defaultItem: {
          json_institucion: [],
          json_especialidad: [],
          caracteristicas: {
            nombre: 'json_institucion',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        institucion_especialidad: {},
        items_data_table: [],   
        items_niveles: {
          json_group_niveles: [],
          tx_nivel: '',
          tx_etiqueta: '',
          tx_descripcion: '',
          nu_id_padre: '',
          json_nivel_padre: [],
        },   

        headers: [
            {
              text: 'Año escolar',
              align: 'start',
              sortable: false,
              value: 'tx_nombre_ano',
            },
            { text: 'Nivel Educativo', value: 'tx_nivel' },        
            { text: 'Nivel/Grado/año', value: 'nivel_grado' },        
            { text: 'Áreas de Formación', value: 'grado' },        
            { text: 'Actions', value: 'actions', sortable: false },

        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_academico']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {

  },    
  methods: {
    showPlan() {
      this.fields_academico.group_area_formacion.co_plan_estudio.co_plan_estudio = this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio
    },    
      remove (item) {
        console.log('Items: ', item)
        var index = this.fields_academico.group_area_formacion.json_area.json_area.findIndex(i => i.nu_id_area_formacion === item.nu_id_area_formacion);
        if (index >= 0) this.fields_academico.group_area_formacion.json_area.json_area.splice(index, 1)
      },    
      searchAreaFormacion(q) {
          this.$store.dispatch(LIST_AREAS_FORMACION, {q: q}).then(response => {
          this.list_areas_formacion = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.cleanig()
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {

          //const resultado = this.fields_academico.group_area_formacion.json_group_area_formacion.json_group_area_formacion.find( plan_estudio => plan_estudio.co_plan_estudio === this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio );
          // this.grupos_area_formacion.push(
          //   {

          //     nu_id_grado: this.fields_academico.group_area_formacion.json_grados.json_grados.nu_id_grado,
          //     tx_nombre_grado: this.fields_academico.group_area_formacion.json_grados.json_grados.tx_nombre_grado,
          //     area: this.fields_academico.group_area_formacion.json_area.json_area
          //   }
          // )
          var gg = {}
          gg = {
                nu_id_grado: this.fields_academico.group_area_formacion.json_grados.json_grados.nu_id_grado,
                tx_nombre_grado: this.fields_academico.group_area_formacion.json_grados.json_grados.tx_nombre_grado,
                area: this.fields_academico.group_area_formacion.json_area.json_area
              }                      

          //this.grupos_area_formacion[this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio] = gg
          //this.grupos_area_formacion_todos[this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio] = this.grupos_area_formacion[this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio];
          var hash = {}
          hash = {
              tx_nombre_ano: this.fields_academico.group_ano_escolar.tx_nombre_ano.tx_nombre_ano,
              nu_id_plan: this.fields_academico.group_area_formacion.json_nivel.json_nivel.nu_id_plan,
              co_plan_estudio: this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio,
              nu_id_nivel_educativo: this.fields_academico.group_area_formacion.json_nivel.json_nivel.nu_id_nivel_educativo,
              tx_nivel: this.fields_academico.group_area_formacion.json_nivel.json_nivel.tx_nivel,
              grado: [gg],
              nivel_grado: [gg]

          }

          const  resultado = this.fields_academico.group_area_formacion.json_group_area_formacion.json_group_area_formacion.find( plan_estudio => plan_estudio.co_plan_estudio === this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio )

          console.log('Hash: ', hash[this.fields_academico.group_area_formacion.json_nivel.json_nivel.co_plan_estudio])

          if (resultado) {
            console.log('resultado Encontrado: ', resultado)
            resultado.grado.push(gg)
            resultado.nivel_grado.push(gg)
          } else {
            console.log('resultado NO Encontrado: ')
            this.fields_academico.group_area_formacion.json_group_area_formacion.json_group_area_formacion.push(hash)
          }




          console.log('json_group_area_formacion: ', this.fields_academico.group_area_formacion.json_group_area_formacion.json_group_area_formacion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        //this.searchInstitucion(item.json_institucion.nu_id_institucion)
        this.editedIndex = this.fields_academico.group_niveles.json_group_niveles.json_group_niveles.indexOf(item)
        const claves_primarias = {}
        claves_primarias.tx_nombre_ano = 'tx_nombre_ano'
        for (const property in item) {
            //console.log('item: ', property)
            if (claves_primarias[property] != property) {
              this.fields_academico.group_niveles[property][property] = item[property]
            }               
        }

        this.dialog = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_group_area_formacion = 'json_group_area_formacion'

        for (const property in this.fields_academico.group_area_formacion) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_academico.group_area_formacion[property][property] = []
              } else {
                this.fields_academico.group_area_formacion[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        this.editedIndex = this.fields_academico.group_area_formacion.json_group_area_formacion.json_group_area_formacion.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_academico.group_area_formacion.json_group_area_formacion.json_group_area_formacion.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    },  

  
  mounted() {
    this.searchAreaFormacion();
    if (this.send_datos.operacion === 'EDITAR') {
        //this.fields_academico.group_niveles.json_institucion.json_institucion = JSON.parse(this.send_datos.usuario.json_institucion)
        //this.fields_academico.group_niveles.json_especialidad.json_especialidad = JSON.parse(this.send_datos.usuario.json_especialidad)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_academico[prop] === 'object') {
          //           if (this.fields_academico[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_academico[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_academico[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_academico[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.list_especialidades = JSON.parse(this.fields_academico.group_niveles.json_institucion.json_institucion[0].json_especialidad)
        //this.fields_academico.group_niveles.json_group_niveles.json_group_niveles = []
        this.fields_academico.isEditing = true
    }           
  },
    
}
</script>